<template>
  <validation-observer ref="simpleRules">
    <div>
      <!-- <this-header /> -->
      <div class="anan-set-nav-conttainer mt-150">
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12">
            <side-bar class="d-none d-md-block" />
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12">
            <h3>{{ $t('addressBook') }}</h3>
            <b-overlay
              :show="showOver"
              variant="transparent"
              opacity="0.99"
              blur="5px"
              rounded="sm"
            >
              <template #overlay>
                <div class="text-center">
                  <p id="cancel-label">
                    กรุณารอสักครู่...
                  </p>
                </div>
              </template>
              <div class="dashboard card mt-1 rounded-0">
                <div>
                  <div
                    class="header-cardx p-2 d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <h3 class="mb-0">
                        {{ $t('myAddress') }}
                      </h3>
                      <small
                        class="text-muted"
                      >{{ $t('setDeliveryAddress') }}</small>
                    </div>

                    <button
                      class="btn btn-gradient-primary btn-mb ml-1"
                      @click="showAddAddr()"
                    >
                      <i class="far fa-plus" /> {{ $t('addAddress') }}
                    </button>
                  </div>
                  <!-- <div
                  v-for="items in Address"
                  :key="items.id"
                  class="view"
                > -->

                  <!-- </div> -->
                  <div
                    v-for="item in items"
                    :key="item"
                    class="view"
                  >
                    <div class="icon">
                      <i class="fas fa-map-marker-alt fa-2x text-success" />
                    </div>
                    <div class="content">
                      <div class="grider">
                        <div class="label">
                          {{ $t('key-77') }}
                        </div>
                        <div class="name">
                          {{ item.fullname }}
                        </div>
                      </div>
                      <div class="grider">
                        <div class="label">
                          {{ $t('key-146') }}
                        </div>
                        <div class="tel">
                          {{ item.tel }}
                        </div>
                      </div>
                      <div class="grider">
                        <div class="label">
                          {{ $t('address') }}
                        </div>
                        <div class="detail">
                          {{ item.address }} <br>
                          {{ $t('subdistrict') }} {{ item.subdistrict }}, {{ $t('district') }} {{ item.district }} <br>
                          {{ $t('province') }}{{ item.province }} {{ item.postalcode }}
                        </div>
                      </div>
                    </div>
                    <div class="operations text-r">
                      <div class="edit">
                        <button
                          type="button"
                          class="anan-button anan-button--link anan-button--normal"
                          @click="showAddAddr(item, index)"
                        >
                          <span> {{ $t('edit') }} </span>
                        </button>
                      </div>
                      <div class="delete">
                        <button
                          type="button"
                          class="anan-button anan-button--link anan-button--normal"
                          @click="DeleteData(item)"
                        >
                          <span> {{ $t('delete') }} </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="add-addr"
      :title="$t('addNewAddress')"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      @ok.prevent="addAddr(index)"
    >
      <validation-observer ref="addRules">
        <b-form-group
          label-for="h-name"
          :label="$t('key-77')"
        >
          <validation-provider
            #default="{ errors }"
            name="Full Name"
            rules="required"
          >
            <b-form-input
              id="h-name"
              v-model="fullName"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-phone-number"
          :label="$t('key-146')"
        >
          <validation-provider
            #default="{ errors }"
            name="Phone Number"
            rules="required"
          >
            <b-form-input
              id="h-phone-number"
              v-model="phoneNumber"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-address"
          :label="$t('addressNumber')"
        >
          <validation-provider
            #default="{ errors }"
            name="Address"
            rules="required"
          >
            <b-form-textarea
              id="h-address"
              v-model="address"
              row="2"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-province-name"
          :label="$t('province')"
        >
          <validation-provider
            #default="{ errors }"
            name="Province"
            rules="required"
          >
            <v-select
              id="h-province-name"
              v-model="selectedProvince"
              label="province_name"
              :options="provinceOptions"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.province_id"
              @input="updateDistricts"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-district-name"
          :label="$t('district')"
        >
          <validation-provider
            #default="{ errors }"
            name="District"
            rules="required"
          >
            <v-select
              id="h-district-name"
              v-model="selectedDistrict"
              label="district_name"
              :options="filteredDistricts"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.district_id"
              @input="updateSubDistricts"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-sub-district-name"
          :label="$t('subdistrict')"
        >
          <validation-provider
            #default="{ errors }"
            name="Sub District"
            rules="required"
          >
            <!-- @click=";(subDistrict = null), (postalCode = null)" -->
            <v-select
              id="h-sub-district-name"
              v-model="selectedSubDistrict"
              label="sub_district_name"
              :options="filteredSubDistricts"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.tumbon_id"
              @input="updatePostalcode"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-postal-code"
          :label="$t('postalCode')"
        >
          <validation-provider
            #default="{ errors }"
            name="Postal Code"
            rules="required"
          >
            <b-form-input
              id="h-postal-code"
              v-model="postalcode"
              label="zipcode"
              :state="errors.length > 0 ? false : null"
              :disabled="postalcode"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
  BOverlay,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
// import { filter } from 'postcss-rtl/lib/affected-props'
import SideBar from './component/SideBar.vue'

export default {
  name: 'CustomerNewView',
  components: {
    // ThisHeader,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BModal,
    SideBar,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // Validation
      required,

      // Add Address
      Address: [],
      id: null,
      items: [],
      itemspvc: [],
      idistrict: [],
      iSubdistrict: [],
      izipcode: [],
      fullName: null,
      phoneNumber: null,
      zipcode: null,
      // address: null,
      province: [],
      district: [],
      subDistrict: [],
      postalcode: null,
      selectedProvince: null,
      selectedDistrict: null,
      selectedSubDistrict: null,
      options: [
        { value: 'all', text: 'ทั้งหมด' },
        { value: 1, text: 'เติมเงิน' },
        { value: 2, text: 'ถอนเงิน' },
        { value: 3, text: 'ชำระค่านำเข้า' },
        { value: 4, text: 'ค่าสินค้า' },
        { value: 5, text: 'ส่วนต่างค่าขนส่งในไทย' },
        { value: 6, text: 'ค่าสินค้าใน MALL' },
        { value: 7, text: 'ฝากจ่ายเถาเปา' },
        { value: 8, text: 'ค่า Commission' },
        { value: 9, text: 'เติมเงินอาลีเพย์' },
        { value: 10, text: 'โอนเงินไปจีน' },
        { value: 11, text: 'แจ้งไม่ได้รับสินค้า' },
        { value: 12, text: 'แจ้งร้านค้าคืนเงิน' },
        { value: 13, text: 'ค่าจัดส่งในไทย' },
        { value: 14, text: 'แลกเงินหยวนเป็นเงินบาท' },
        { value: 15, text: 'ส่งของไปจีน' },
        { value: 16, text: 'แลกแต้ม' },
        // { value: 17, text: 'อื่นๆ' },
      ],
      showOver: false,
    }
  },
  computed: {
    provinceOptions() {
      return this.itemspvc.map(item => ({
        province_id: item.province_id,
        province_name: item.province_name,
      }))
    },
    filteredDistricts() {
      if (this.selectedProvince) {
        return this.idistrict.filter(ele => (ele.province_id === this.selectedProvince))
          .map(item => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }))
      }
      return this.idistrict.map(item => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }))
    },
    filteredSubDistricts() {
      if (this.selectedDistrict) {
        return this.iSubdistrict.filter(ele => (ele.ampur_id === this.selectedDistrict))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      if (this.selectedProvince) {
        return this.iSubdistrict.filter(ele => (ele.changwat_id === this.selectedProvince))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      return this.iSubdistrict.map(item => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }))
    },
    filteredpostalCode() {
      if (this.selectedSubDistrict) {
        return this.izipcode.filter(ele => (ele.tum_id === this.selectedSubDistrict))
          .map(item => ({
            tum_id: item.tum_id,
            zipcode: item.zipcode,
          }))
      }
      return this.izipcode.map(item => ({
        tum_id: item.tum_id,
        zipcode: item.zipcode,
      }))
    },
  },

  async mounted() {
    this.getData()
    await this.getProvince()
    await this.getDistrict()
    await this.getSubdistrict()
    await this.getzipcode()
  },
  methods: {
    async getData() {
      this.showOver = true
      try {
        const { data: res } = await this.$http.get('/Profilecustomer/showAddress')
        this.items = res
        this.showOver = false
        // res.forEach(ele => {
        //   this.items = ele
        //   console.log(this.items)
        // })
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    async getProvince() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/province') // จังหวัด
        this.itemspvc = res
      } catch (e) {
        console.log(e)
      }
    },
    async getDistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/district') // อำเภอ
        this.idistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getSubdistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/Subdistrict') // ตำบล
        this.iSubdistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getzipcode() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/zipcode') // ตำบล
        this.izipcode = res
      } catch (e) {
        console.log(e)
      }
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(result => {
          if (result) {
            const params = {
              id: data.id,
            }
            this.$http.post('/Addresscustomer/deleteAddress', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateDistricts() {
      this.selectedDistrict = null
      this.selectedSubDistrict = null
    },
    updateSubDistricts() {
      this.selectedSubDistrict = null
      this.postalcode = null
    },
    updatePostalcode() {
      const arr = this.izipcode.find(ele => (ele.tum_id === this.selectedSubDistrict))
      this.postalcode = arr.zipcode
    },
    addAddr(index) {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          if (index != null && index >= 0) {
            const Obj = {
              id: this.id,
              uid: this.uid,
              fullname: this.fullName,
              tel: this.phoneNumber,
              address: this.address,
              province: '',
              province_id: this.selectedProvince,
              district: '',
              district_id: this.selectedDistrict,
              subdistrict: '',
              subdistrict_id: this.selectedSubDistrict,
              postalcode: this.postalcode,
            }
            if (this.selectedProvince) {
              const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvince))
              Obj.province = provi.province_name
            }
            if (this.selectedDistrict) {
              const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrict))
              Obj.district = arr.district_name
            }
            if (this.selectedSubDistrict) {
              const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrict))
              Obj.subdistrict = arr.sub_district_name
            }
            this.$http
              .post('/Addresscustomer/UpdateAddress', Obj)
              .then(() => {
                this.Success('เพิ่มที่อยู่')
                this.getData(this.isActive)
                this.$bvModal.hide('add-addr')
              })
              .catch(error => {
                this.SwalError(error.response.data.message)
              })
          } else {
            const Obj = {
              id: this.id,
              uid: this.uid,
              fullname: this.fullName,
              tel: this.phoneNumber,
              address: this.address,
              province: '',
              province_id: this.selectedProvince,
              district: '',
              district_id: this.selectedDistrict,
              subdistrict: '',
              subdistrict_id: this.selectedSubDistrict,
              postalcode: this.postalcode,
            }
            console.log(Obj)
            if (this.selectedProvince) {
              const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvince))
              Obj.province = provi.province_name
            }
            if (this.selectedDistrict) {
              const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrict))
              Obj.district = arr.district_name
            }
            if (this.selectedSubDistrict) {
              const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrict))
              Obj.subdistrict = arr.sub_district_name
            }
            this.$http
              .post('/Addresscustomer/UpdateAddress', Obj)
              .then(() => {
                this.Success('เพิ่มที่อยู่')
                this.getData(this.isActive)
                this.$bvModal.hide('add-addr')
              })
              .catch(error => {
                this.SwalError(error.response.data.message)
              })
          }
        }
      })
    },
    showAddAddr(data, index) {
      this.$bvModal.show('add-addr')
      if (data) {
        this.index = index
        this.id = data.id
        this.fullName = data.fullname
        this.phoneNumber = data.tel
        this.address = data.address
        this.selectedProvince = data.province_id
        this.selectedDistrict = data.district_id
        this.selectedSubDistrict = data.subdistrict_id
        this.postalcode = data.postalcode
      } else {
        this.index = null
        this.id = null
        this.fullName = null
        this.phoneNumber = null
        this.address = null
        this.selectedProvince = null
        this.selectedDistrict = null
        this.selectedSubDistrict = null
        this.postalcode = null
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@media only screen and (max-width: 600px) {
.btn-mb{
  font-size: 11px !important;
  width: 120px;
}
}
</style>
